import _omit from 'lodash/omit';
import _remove from 'lodash/remove';
import api from '@/global/api';
import { COIN_SEQUENCE } from '@/processes/Router';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPoolStats: build.query({
      query: () => ({
        url: `/v1/stats/pool`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        let data = response || {};
        try {
          // sort coins in custom order
          data.cryptoCurrencies.sort((a: any, b: any) => {
            return COIN_SEQUENCE.indexOf(a.name) - COIN_SEQUENCE.indexOf(b.name);
          });
          // remove ETH, GRAM
          data = _omit(data, 'stats.ETH');
          data = _omit(data, 'stats.GRAM');

          _remove(data?.cryptoCurrencies, (currency: any) => currency.name === 'ETH' || currency.name === 'GRAM');
        } catch (e) {
          console.error(e);
        }
        return data;
      }
    }),
  }),
});

export const {
  useGetPoolStatsQuery,
} = extendedApi;

export const {
  getPoolStats,
} = extendedApi.endpoints;
